<template>
  <div class="bt-anggota pa-5 mb-5">
    <Header class="text-h6 font-weight-bold mb-4" title="Angsuran Anggota" titleIcon="mdi-account-group" />

    <div class="mt-5">
      <v-autocomplete solo label="Rembug" class="mb-4" hide-details :items="rembug" item-text="cm_name" item-value="cm_code" v-model="cm_code" readonly />

      <v-autocomplete solo label="Kelompok" class="mb-4" hide-details :items="kelompok" item-text="nama_kelompok" item-value="kode_kelompok" v-model="kode_kelompok" readonly />

      <v-menu ref="menu" v-model="dateShow" :close-on-content-click="false" :return-value.sync="transaction_date" transition="scale-transition" offset-y min-width="auto" class="white" disabled>
        <template v-slot:activator="{ on, attrs }">
          <v-text-field solo v-model="transaction_date" label="Tanggal" readonly v-bind="attrs" v-on="on" />
        </template>
        <v-date-picker v-model="transaction_date" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dateShow = false"> Cancel </v-btn>
          <v-btn text color="primary"> OK </v-btn>
        </v-date-picker>
      </v-menu>

      <v-card class="elevation-3 rounded-lg pa-3 align-items-end mb-3">
        <div class="d-flex justify-space-between">
          <span><b>Total Setoran</b></span>
          <h5>Rp {{ thousand(setoran_total_anggota) }}</h5>
        </div>
      </v-card>

      <v-card class="rounded-lg pa-3 mb-3" v-if="setoran_anggota && setoran_anggota.length > 0">
        <v-row no-gutters v-for="(item, index) in setoran_anggota" :key="index">
          <v-col>
            <label>{{ item.nama }} {{ item.total_angsuran }}</label>
          </v-col>
          <v-col>
            <v-text-field
              v-model="item.freq"
              @input="updateFreq(item)"
              type="number"
              min="0"
              :rules="[() => !!item.freq || 'This field is required', () => item.freq <= item.jangka_waktu - item.counter_angsuran || 'Tidak boleh melebihi sisa waktu']"
              dense
              clearable
            ></v-text-field>
          </v-col>
          <v-col class="text-right">
            <v-btn outlined rounded small color="purple lighten-2" @click="openDialog(index, item)" class="ml-auto"> Rp. {{ thousand(item.setoran_temp) }} </v-btn>
          </v-col>
        </v-row>
      </v-card>
      <v-card class="rounded-lg pa-3 mb-3" v-else>
        <v-row no-gutters>
          <v-col class="text-center"> Loading... </v-col>
        </v-row>
      </v-card>

      <v-row>
        <v-col cols="6" class="pb-0 mb-5">
          <router-link :to="`/angsuran/kelompok/${cm_code}/${branch_id}/${transaction_date}`">
            <v-btn block class="purple lighten-1 white--text"> Kembali </v-btn>
          </router-link>
        </v-col>
        <v-col cols="6" class="pb-0">
          <v-btn block class="purple lighten-1 white--text" @click="doSave()"> Simpan </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="text-h5"> Info CIF </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Angsuran Pokok" :value="dialogInput.angsuran_pokok" type="number" disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Angsuran Margin" :value="dialogInput.angsuran_margin" type="number" dense disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Sihata" :value="dialogInput.sihata_mingguan" type="number" dense disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="10">
                <v-text-field label="Simpanan Khusus" :value="dialogInput.angsuran_catab" type="number" dense disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-checkbox v-model="dialogInput.status_installment_catab" @change="updateAngsuranCatab()"></v-checkbox>
              </v-col>
              <v-col cols="12" sm="10">
                <v-text-field label="Cadangan Resiko" :value="dialogInput.cadangan_resiko" dense disabled></v-text-field>
              </v-col>
              <v-col cols="12" sm="2">
                <v-checkbox v-model="dialogInput.status_cadangan_resiko" @change="updateCadanganResiko()"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Total Angsuran" :value="dialogInput.setoran" dense disabled></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field label="Muqossah" v-model="dialogInput.cash_back" @input="updateMuqossah()" v-mask="thousandMask" dense :disabled="dialogInput.jangka_waktu - dialogInput.counter_angsuran != dialogInput.freq"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>

    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import helper from "@/utils/helper";
import Toast from "@/components/Toast";
import Header from "../../components/Header.vue";
import { mapGetters } from "vuex";
import services from "@/services";
export default {
  name: "AngsuranAnggota",
  components: {
    Toast,
    Header,
  },
  data() {
    return {
      overlay: false,
      rembug: [],
      kelompok: [],
      setoran_anggota: [],
      setoran_total_anggota: 0,
      cm_code: null,
      kode_kelompok: null,
      branch_id: null,
      transaction_date: null,
      dateShow: false,
      dialog: false,
      dialogInput: {
        angsuran_pokok: 0,
        angsuran_margin: 0,
        sihata_mingguan: 0,
        status_installment_catab: 0,
        angsuran_catab: 0,
        status_cadangan_resiko: 0,
        cadangan_resiko: 0,
        setoran: 0,
        cash_back: 0,
        jangka_waktu: 0,
        counter_angsuran: 0,
      },
      dialogInputIndex: 0,
      alert: {
        show: false,
        msg: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    async validateFreq(item) {
      console.log(item, typeof item.freq, item.freq);
    },
    async updateCadanganResiko() {
      if (this.dialogInput.status_cadangan_resiko) {
        this.dialogInput.setoran = this.dialogInput.setoran + this.dialogInput.cadangan_resiko;
        this.dialogInput.setoran_temp = this.dialogInput.setoran_temp + this.dialogInput.cadangan_resiko;
      } else {
        this.dialogInput.setoran = this.dialogInput.setoran - this.dialogInput.cadangan_resiko;
        this.dialogInput.setoran_temp = this.dialogInput.setoran_temp - this.dialogInput.cadangan_resiko;
      }

      this.setoran_anggota[this.dialogInputIndex].setoran = this.dialogInput.setoran;

      this.updateFreq(this.dialogInput);
    },
    async updateAngsuranCatab() {
      if (this.dialogInput.status_installment_catab) {
        this.dialogInput.setoran = this.dialogInput.setoran + this.dialogInput.angsuran_catab;
        this.dialogInput.setoran_temp = this.dialogInput.setoran_temp + this.dialogInput.angsuran_catab;
      } else {
        this.dialogInput.setoran = this.dialogInput.setoran - this.dialogInput.angsuran_catab;
        this.dialogInput.setoran_temp = this.dialogInput.setoran_temp - this.dialogInput.angsuran_catab;
      }

      this.setoran_anggota[this.dialogInputIndex].setoran = this.dialogInput.setoran;

      this.updateFreq(this.dialogInput);
    },
    async updateMuqossah() {
      this.setoran_anggota[this.dialogInputIndex].cash_back = this.dialogInput.cash_back;
      this.updateSetoranTotalAnggota();
    },
    async openDialog(index, item) {
      this.dialogInput = item;
      this.dialogInputIndex = index;
      this.dialog = true;
    },
    async updateFreq(item) {
      item.setoran_temp = item.setoran * item.freq;
      this.updateSetoranTotalAnggota();
    },
    async updateSetoranTotalAnggota() {
      this.setoran_total_anggota = this.setoran_anggota.reduce((a, b) => a + b.setoran_temp, 0);
    },
    async getRembug() {
      try {
        let payload = new FormData();
        payload.append("fa_code", this.user.fa_code);
        payload.append("hari_transaksi", "99");

        this.overlay = true;
        const response = await services.infoRembug(payload, this.user.token);
        if (response.status === 200) {
          if (Array.isArray(response.data.data)) {
            this.rembug = response.data.data;
          } else {
            this.alert = {
              show: true,
              msg: "Rembug tidak ditemukan",
            };
          }
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      } finally {
        this.overlay = false;
      }
    },
    async getKelompok() {
      const fa_code = this.user.fa_code || null;
      const account_cash_code = this.user.account_cash_code || null;

      let payload = new FormData();
      payload.append("cm_code", this.cm_code);
      payload.append("branch_id", this.branch_id);
      payload.append("transaction_date", this.transaction_date);
      payload.append("fa_code", fa_code);
      payload.append("account_cash_code", account_cash_code);

      try {
        this.overlay = true;
        const response = await services.infoGroup(payload, this.user.token);
        if (response.status === 200) {
          const { kelompok } = response.data.data;
          this.kelompok = kelompok;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
      } finally {
        this.overlay = false;
      }
    },
    async getAnggota() {
      let payload = new FormData();
      payload.append("cm_code", this.cm_code);
      payload.append("kode_kelompok", this.kode_kelompok);
      payload.append("transaction_date", this.transaction_date);

      try {
        this.overlay = true;
        const response = await services.infoMemberFinancing(payload, this.user.token);

        if (response.status === 200) {
          const { setoran = [], total_setoran = 0 } = response.data.data;

          for (let item of setoran) {
            Object.assign(item, {
              cash_back: item.cash_back,
              setoran_temp: item.setoran * item.freq,
            });
          }

          this.setoran_anggota = setoran;
          this.setoran_total_anggota = total_setoran;
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || error,
        };
      } finally {
        this.overlay = false;
      }
    },
    async doSave() {
      this.overlay = true;
      try {
        let payload = new FormData();

        for (let [index, item] of this.setoran_anggota.entries()) {
          const { status_installment_catab, status_cadangan_resiko, ...rest } = item;

          for (let [key, value] of Object.entries(rest)) {
            if (value === null || value === "") {
              throw new Error(`${key.toUpperCase()} tidak boleh kosong`);
            }

            payload.append(`${key}[${index}]`, value);
          }

          payload.append(`status_installment_catab[${index}]`, Number(status_installment_catab));
          payload.append(`status_cadangan_resiko[${index}]`, Number(status_cadangan_resiko));
        }

        const response = await services.transactionSetorAngsuran(payload, this.user.token);

        if (response.status == 200) {
          this.alert = {
            show: true,
            msg: response.data?.data?.msg || "Berhasil disimpan!",
          };

          setTimeout(() => {
            this.$router.push(`/angsuran/kelompok/${this.cm_code}/${this.branch_id}/${this.transaction_date}`);
          }, 500);
        } else {
          throw new Error(response.message);
        }
      } catch (error) {
        this.alert = {
          show: true,
          msg: error.message || error,
        };
      } finally {
        this.overlay = false;
      }
    },
  },
  mounted() {
    this.cm_code = this.$route.params.cm_code;
    this.branch_id = this.$route.params.branch_id;
    this.transaction_date = this.$route.params.transaction_date;
    this.kode_kelompok = parseInt(this.$route.params.kode_kelompok, 10);

    this.getRembug();
    this.getKelompok();
    this.getAnggota();
  },
};
</script>
